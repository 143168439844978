import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import PhoneInput from '../PhoneInput';
import styles from './Login.module.css';
import logo from '../../img/Logo_Oakwood_Sign_In.svg';
import GlobalConfig from "../../utils/GlobalConfig";

function Login({
  bindPhoneNumber,
  handleSignIn
}) {

  return (
    <div className={styles.Login} style={{ color: (GlobalConfig.get(GlobalConfig.Key.TEXT_FG_COLOR)) }}>
      <img src={logo} alt="Oakwood Homes" />
      <h1>Log in or sign up</h1>
      <div className={styles.message}>Please enter your phone number</div>
      <form id="form-login" onSubmit={handleSignIn}>
        <PhoneInput bindPhoneNumber={bindPhoneNumber} />
      </form>
      <div>
        <Button
          background={bindPhoneNumber.value.length === 10 ? GlobalConfig.get(GlobalConfig.Key.BUTTON_SECONDARY_BG_COLOR) : GlobalConfig.get(GlobalConfig.Key.BUTTON_DISABLED_BG_COLOR)}
          color={bindPhoneNumber.value.length === 10 ? '#fff' : '#7a7a7a'}
          disabled={bindPhoneNumber.value.length !== 10}
          form="form-login"
          text="continue"
          type="submit"
        />
        <p>
          By using this service, you agree to the
          &nbsp;
          <a
            href="https://oakwoodhomesco.com/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            privacy policy
          </a>
          .
        </p>
      </div>
    </div>
  );
}

Login.propTypes = {
  bindPhoneNumber: PropTypes.object.isRequired,
  handleSignIn: PropTypes.func.isRequired
};

export default Login;
