import GlobalConfig from "../../utils/GlobalConfig";

const handleError = (err, setAlert) => {
  if (err.alert) {
    setAlert(err.alert);
  } else {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const handlePostMessage = (targetOrigin, message) => {
  const whitelist = GlobalConfig.get(GlobalConfig.Key.TARGET_ORIGIN_WHITELIST).split(',');
  if (whitelist.includes(targetOrigin)) {
    window.parent.postMessage(message, targetOrigin);
  } else {
    console.error('Invalid targetOrigin!');
  }
};

export {
  handleError,
  handlePostMessage
};
