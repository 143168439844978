import React, { useState } from 'react';
import PropTypes from 'prop-types';
import listDigits from './utils';
import styles from './PhoneInput.module.css';
import GlobalConfig from "../../utils/GlobalConfig";

function PhoneInput({ bindPhoneNumber }) {
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <div className={`${styles.PhoneInput} ${hasFocus ? styles.focus : ''}`} style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_BG_COLOR)), color: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_FG_COLOR)), border: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_BG_BORDER)) }}>
      <input
        minLength={10}
        maxLength={10}
        name="phone"
        onBlur={() => setHasFocus(false)}
        onChange={(e) => {
          if (!Number.isNaN(Number(e.target.value))) {
            bindPhoneNumber.onChange(e);
          }
        }}
        onFocus={() => setHasFocus(true)}
        required
        type="tel"
        value={bindPhoneNumber.value}
      />
      <div className={styles.output}>
        <span>{listDigits([1, 2, 3], bindPhoneNumber.value)}</span>
        <span>{listDigits([4, 5, 6], bindPhoneNumber.value)}</span>
        <span>{listDigits([7, 8, 9, 10], bindPhoneNumber.value)}</span>
      </div>
    </div>
  );
}

PhoneInput.propTypes = {
  bindPhoneNumber: PropTypes.object.isRequired
};

export default PhoneInput;
