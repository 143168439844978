import React from 'react';
import Digit from './Digit';

const listDigits = (digits, phoneNumber) => {
  const phoneNumberArr = phoneNumber.split('');

  return digits.map((x) => (
    // eslint-disable-next-line react/no-array-index-key
    <Digit key={x} value={phoneNumberArr[x - 1]} />
  ));
};

export default listDigits;
