class GlobalConfig {
  constructor() {
    this.config = {};
    this.Key = {
      BASENAME:'BASENAME',
      API_URL:'API_URL',
      SOCKET_KOVA_ENV:'SOCKET_KOVA_ENV',
      TARGET_ORIGIN:'TARGET_ORIGIN',
      TARGET_ORIGIN_WHITELIST:'TARGET_ORIGIN_WHITELIST',
      PRIMARY_BG_COLOR:'PRIMARY_BG_COLOR',
      SECONDARY_BG_COLOR:'SECONDARY_BG_COLOR',
      BUTTON_DISABLED_BG_COLOR:'BUTTON_DISABLED_BG_COLOR',
      BUTTON_PRIMARY_FG_COLOR:'BUTTON_PRIMARY_FG_COLOR',
      BUTTON_PRIMARY_BG_COLOR:'BUTTON_PRIMARY_BG_COLOR',
      BUTTON_PRIMARY_BORDER:'BUTTON_PRIMARY_BORDER',
      BUTTON_SECONDARY_FG_COLOR:'BUTTON_SECONDARY_FG_COLOR',
      BUTTON_SECONDARY_BG_COLOR:'BUTTON_SECONDARY_BG_COLOR',
      BUTTON_SECONDARY_BORDER:'BUTTON_SECONDARY_BORDER',
      TEXTINPUT_FG_COLOR:'TEXTINPUT_FG_COLOR',
      TEXTINPUT_BG_COLOR:'TEXTINPUT_BG_COLOR',
      TEXTINPUT_BG_BORDER:'TEXTINPUT_BG_BORDER',
      TEXT_FG_COLOR:'TEXT_FG_COLOR'
    };
  }

  setConfig(config) {
    this.config = config;
  }

  get(key) {
    return this.config[key];
  }
}

const configInstance = new GlobalConfig();

export default configInstance;
