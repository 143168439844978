import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

function Button({
  background,
  border,
  clickHandler,
  color,
  disabled,
  form,
  text,
  type
}) {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={styles.Button}
      form={form}
      onClick={clickHandler}
      style={{ background, color, pointerEvents: disabled ? 'none' : 'auto', border }}
      type={type}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  background: '#191919',
  border: '#191919',
  color: '#fff',
  clickHandler: null,
  disabled: false,
  form: null,
  type: 'button'
};

Button.propTypes = {
  background: PropTypes.string,
  border: PropTypes.string,
  clickHandler: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default Button;
