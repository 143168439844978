import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator';
import styles from './LoadingOverlay.module.css';
import GlobalConfig from "../../utils/GlobalConfig";

function LoadingOverlay({ status }) {
  return (
    <div className={styles.LoadingOverlay} style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.PRIMARY_BG_COLOR)) }}>
      <LoadingIndicator />
      <div className={styles.status}>{status}</div>
    </div>
  );
}

LoadingOverlay.propTypes = {
  status: PropTypes.string.isRequired
};

export default LoadingOverlay;
