import AlertError from '../../errors/Alert';
import GlobalConfig from "../GlobalConfig";

export default class Api {
  static fetchCustomerInfo = async (phoneNumber) => {
    try {
      const response = await fetch(`${GlobalConfig.get(GlobalConfig.Key.API_URL)}ValidatePhone?phoneHome=${phoneNumber}&kovaEnv=${GlobalConfig.get(GlobalConfig.Key.SOCKET_KOVA_ENV)}`);

      if (!response.ok) {
        const errMessage = await response.text();
        const message = errMessage || `${response.status} ${response.statusText}`;
        const alert = {
          id: 'fetchCustomerInfo',
          heading: 'Error',
          message
        };
        throw new AlertError(alert, message);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      const message = `fetchCustomerInfo failed - ${err.message}`;
      const alert = {
        id: 'fetchCustomerInfo',
        heading: 'Error',
        message
      };
      throw new AlertError(alert, message);
    }
  }

  static registerCustomer = async (customer) => {
    try {
      const url = `${GlobalConfig.get(GlobalConfig.Key.API_URL)}RegisterCustomer`;
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const response = await fetch(url, {
        headers,
        method: 'POST',
        body: JSON.stringify(customer)
      });

      if (!response.ok) {
        const errMessage = await response.text();
        const message = errMessage || `${response.status} ${response.statusText}`;
        const alert = {
          id: 'saveCustomerInfo',
          heading: 'Error',
          message
        };
        throw new AlertError(alert, message);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      const message = `saveCustomerInfo failed - ${err.message}`;
      const alert = {
        id: 'saveCustomerInfo',
        heading: 'Error',
        message
      };
      throw new AlertError(alert, message);
    }
  }
}
