const alerts = {
  critical: {
    postMessageOrigin: {
      id: 'postMessageOrigin',
      heading: 'Error',
      message: 'postMessage failed: parent window origin does not match targetOrigin.'
    }
  },
  validation: {
    email: {
      heading: 'Validation Error',
      message: 'Emails must match.'
    }
  }
};

export default alerts;
