import React from 'react';
import PropTypes from 'prop-types';
import MiscUtils from '../../utils/Misc';
import Button from '../Button';
import styles from './Register.module.css';
import warningIcon from '../../img/Icon_Warning.svg';
import GlobalConfig from "../../utils/GlobalConfig";

function Register({
  bindFirstName,
  bindLastName,
  bindEmail,
  bindEmailConfirm,
  handleRegister,
  handleRegisterCancel,
  phoneNumber
}) {
  return (
    <div className={styles.Register} style={{ color: (GlobalConfig.get(GlobalConfig.Key.TEXT_FG_COLOR)) }}>
      <img alt="warning" src={warningIcon} />
      <h1>You must be new here!</h1>
      <div className={styles.message}>
        It doesn&apos;t look like we&apos;ve seen you before. Please enter your
        information below. We won&apos;t share it with anyone.
      </div>
      <h2>{`Registering Phone Number: ${phoneNumber && MiscUtils.formatPhoneNumber(phoneNumber)}`}</h2>
      <form id="form-register" onSubmit={handleRegister}>
        <div>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindFirstName}
            required
            minLength={1}
            name="firstName"
            placeholder="First Name"
            type="text"
            style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_BG_COLOR)) }}
          />
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindLastName}
            required
            minLength={1}
            name="lastName"
            placeholder="Last Name"
            type="text"
            style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_BG_COLOR)) }}
          />
        </div>
        <div>
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindEmail}
            required
            minLength={1}
            name="email"
            placeholder="Email"
            type="email"
            style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_BG_COLOR)) }}
          />
          <input
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...bindEmailConfirm}
            required
            minLength={1}
            name="emailConfirm"
            placeholder="Confirm Email"
            type="email"
            style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.TEXTINPUT_BG_COLOR)) }}
          />
        </div>
      </form>
      <div>
        <Button
          background={(GlobalConfig.get(GlobalConfig.Key.BUTTON_PRIMARY_BG_COLOR))}
          border={(GlobalConfig.get(GlobalConfig.Key.BUTTON_PRIMARY_BORDER))}
          clickHandler={handleRegisterCancel}
          color="#7a7a7a"
          text="back"
        />
        <Button
          background={(GlobalConfig.get(GlobalConfig.Key.BUTTON_SECONDARY_BG_COLOR))}
          border={(GlobalConfig.get(GlobalConfig.Key.BUTTON_SECONDARY_BORDER))}
          form="form-register"
          text="create account"
          type="submit"
        />
      </div>
      <p>
        By using this service, you agree to the
        &nbsp;
        <a
          href="https://oakwoodhomesco.com/privacy-policy/"
          rel="noopener noreferrer"
          target="_blank"
        >
          privacy policy
        </a>
        .
      </p>
    </div>
  );
}

Register.propTypes = {
  bindFirstName: PropTypes.object.isRequired,
  bindLastName: PropTypes.object.isRequired,
  bindEmail: PropTypes.object.isRequired,
  bindEmailConfirm: PropTypes.object.isRequired,
  handleRegister: PropTypes.func.isRequired,
  handleRegisterCancel: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

export default Register;
