import React from 'react';
import PropTypes from 'prop-types';
import styles from './Digit.module.css';
import GlobalConfig from "../../../utils/GlobalConfig";

function Digit({ value }) {
  return (
    <div className={styles.Digit}>
      <div>{value}</div>
      <div style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.SECONDARY_BG_COLOR)) }} />
    </div>
  );
}

Digit.defaultProps = {
  value: ''
};

Digit.propTypes = {
  value: PropTypes.string
};

export default Digit;
