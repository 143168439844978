import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Switch } from 'react-router-dom';
import queryString from 'query-string';
import useInput from '../../hooks/input';
import ApiUtils from '../../utils/Api';
import MiscUtils from '../../utils/Misc';
import alertConfig from '../../config/alerts';
import {
  handleError,
  handlePostMessage
} from './utils';

import Alert from '../Alert';
import LoadingOverlay from '../LoadingOverlay';
import Routes from '../Routes';

import styles from './App.module.css';
import GlobalConfig from "../../utils/GlobalConfig";

function App() {
  const { value: phoneNumber, bind: bindPhoneNumber, reset: resetPhoneNumber } = useInput('');
  const { value: firstName, bind: bindFirstName, reset: resetFirstName } = useInput('');
  const { value: lastName, bind: bindLastName, reset: resetLastName } = useInput('');
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
  const { value: emailConfirm, bind: bindEmailConfirm, reset: resetEmailConfirm } = useInput('');
  const [user, setUser] = useState(null);
  const [alert, setAlert] = useState(null);
  const [status, setStatus] = useState('');
  const [targetOrigin, setTargetOrigin] = useState(GlobalConfig.get(GlobalConfig.Key.TARGET_ORIGIN));

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setAlert(null);
  }, [location]);

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    if (query.targetOrigin) {
      setTargetOrigin(query.targetOrigin);
    }
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          setStatus('checking community entry...');
          await new Promise((resolve) => {
            setTimeout(resolve, 1000);
          });

          const message = {
            customerData: {
              customerRID: user.CustomerRID
            },
            errors: undefined
          };
          handlePostMessage(targetOrigin, message);
        } catch (err) {
          handleError(err, setAlert);
          handlePostMessage(targetOrigin, {
            customerData: undefined,
            errors: err.alert ? [{ ...err.alert }] : [{ ...err }]
          });
          setStatus('');
        }
        resetPhoneNumber();
        setUser(null);
        setStatus('');
        history.push('/');
      })();
    }
  }, [user]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setAlert(null);
    setStatus('signing-in...');

    try {
      const formattedPhoneNumber = MiscUtils.formatPhoneNumber(phoneNumber);
      const customerInfo = await ApiUtils.fetchCustomerInfo(
        formattedPhoneNumber
      );

      if (!customerInfo || !customerInfo.CustomerRID) {
        setStatus('');
        history.push('/register');
      } else {
        setStatus('customer found...');
        setUser(customerInfo);
      }
    } catch (err) {
      handleError(err, setAlert);
      handlePostMessage(targetOrigin, setAlert, {
        customerData: undefined,
        errors: err.alert ? [{ ...err.alert }] : [{ ...err }]
      });
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    setStatus('generating new customer...');
    await new Promise((resolve) => {
      setTimeout(resolve, 500);
    });

    if (email !== emailConfirm) {
      setAlert(alertConfig.validation.email);
      return;
    }

    const Customer = {
      NameLast: lastName,
      NameFirst: firstName,
      PhoneHome: MiscUtils.formatPhoneNumber(phoneNumber),
      EmailHome: email
    };

    try {
      const customerInfo = await ApiUtils.registerCustomer(Customer);

      setUser(customerInfo);
      setStatus('');
    } catch (err) {
      handleError(err, setAlert);
      handlePostMessage(targetOrigin, setAlert, {
        customerData: undefined,
        errors: err.alert ? [{ ...err.alert }] : [{ ...err }]
      });
      setStatus('');
    }
    resetFirstName();
    // resetMiddleInitial();
    resetLastName();
    resetEmail();
    resetEmailConfirm();
  };

  const handleRegisterCancel = () => {
    history.push('/');
    resetPhoneNumber();
  };

  return (
    <div className={styles.App} style={{ backgroundColor: (GlobalConfig.get(GlobalConfig.Key.PRIMARY_BG_COLOR)) }}>
      <div className={styles.wrapper}>
        {alert && <Alert alert={alert} />}
        <Switch>
          <Routes
            bindEmail={bindEmail}
            bindEmailConfirm={bindEmailConfirm}
            bindFirstName={bindFirstName}
            bindLastName={bindLastName}
            // bindMiddleInitial={bindMiddleInitial}
            bindPhoneNumber={bindPhoneNumber}
            handleRegister={handleRegister}
            handleRegisterCancel={handleRegisterCancel}
            handleSignIn={handleSignIn}
            phoneNumber={phoneNumber}
          />
        </Switch>
        {status && !alert && <LoadingOverlay status={status} />}
      </div>
    </div>
  );
}

export default App;
