import React from 'react';
import styled from 'styled-components';
import styles from './LoadingIndicator.module.css';
import GlobalConfig from "../../utils/GlobalConfig";

const DivStep = styled.div`
  &:after {
    background-color: ${GlobalConfig.get(GlobalConfig.Key.SECONDARY_BG_COLOR)}
  }
`;

function LoadingIndicator() {
  return (
    <div className={styles.LoadingIndicator}>
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
      <DivStep />
    </div>
  );
}

export default LoadingIndicator;
